import axios from "axios";
import { SpinLoading, Dialog } from "antd-mobile";
import loading from "../component/loading";
// import { getStorageValByKey, STORAGE_KEY } from "./storage";
// import "../assets/styles/request.styl";
const ENV = {
  prod: "prod",
  uat: "uat",
};

const env = ENV.prod;

export let BASE_URL = "https://bean-mng-api.bijing.life"; // 基础api域名
export let SMART_BEANS_UPLOAD_TEMPLATE_URL =
  "https://static.bjzl.net.cn/wisdom-bean/1729521929785-批量增加智慧豆_模板2024.10.21.xlsx"; // 智慧豆批量上传模版链接

if (env === ENV.uat) {
  BASE_URL = "https://bean-mng-api.bjzl.net.cn";
  SMART_BEANS_UPLOAD_TEMPLATE_URL =
    "https://static.bjzl.net.cn/wisdom-bean/1729521929785-批量增加智慧豆_模板2024.10.21.xlsx";
}

// 创建axios实例
const service = axios.create({
  baseURL: BASE_URL, //process.env.BASE_API, // base_url
  timeout: 10000, // 请求超时时间
});

let reqCount = 0;

const addReq = () => {
  reqCount++;
  console.log(reqCount);
  if (reqCount === 1) {
    hideLoadig();
    showLoading();
  }
};

const delReq = () => {
  reqCount--;
  if (reqCount === 0) {
    hideLoadig();
  }
};

// request拦截器
service.interceptors.request.use(
  (config) => {
    addReq();
    config.headers["Content-Type"] = "application/json ";
    return config;
  },

  (error) => {
    delReq();
    console.log(error); // for debug
    Promise.reject(error);
  }
);

// respone拦截器
service.interceptors.response.use(
  (response) => {
    delReq();
    const res = response.data;
    if (res.code !== 0) {
      return res; //Promise.reject("error");
    } else {
      return res;
    }
  },
  (error) => {
    console.log("err++++++))))" + error);
    delReq();

    return { code: 5001, message: "error" }; //Promise.reject(error);
  }
);

/**
 * 文件下载
 * @param {*} path
 * @param {*} name
 * @returns
 */
// export const downloadFile = async (path, name, params) => {
//   try {
//     let url = BASE_URL + path;
//     const link = document.createElement("a");
//     const token = getStorageValByKey(STORAGE_KEY.token);
//     let query = "";
//     if (params) {
//       query = Object.keys(params)
//         .map((key) => {
//           if (params[key]) {
//             return key + "=" + params[key];
//           }
//         })
//         .join("&");
//     }
//     link.href = url + `?token=${token}&` + query;
//     link.setAttribute("download", name || "file.xlsx"); // 设置下载的文件名
//     document.body.appendChild(link);
//     link.click();
//     document.body.removeChild(link);
//     return { code: 0 };
//   } catch (error) {
//     console.log("download error:");
//   }
// };

/**
 * 文件上传
 * @param {*} file
 */
// export const uploadFile = async (file) => {
//   const formData = new FormData();
//   formData.append("file", file);

//   try {
//     const res = await service.post(BASE_URL, formData, {
//       headers: {
//         "Content-Type": "multipart/form-data",
//       },
//     });

//     return res;
//   } catch (error) {
//     // 处理错误
//     console.error("文件上传失败:", error);
//   }
// };

export default service;

export function showLoading() {
  // loading.showLoading();
}

function hideLoadig() {
  // loading.hideLoading();
}
