import { useCallback, useRef, useState } from "react";
import { SearchView } from "./view/search";
import { SearchResult } from "./view/searchResult";
import { NavBar, Space } from "antd-mobile";
import { CloseOutline, MoreOutline, SearchOutline } from "antd-mobile-icons";
import { getSmartBeansDetail } from "../../api/searchBeans";

export function SearchBeans() {
  const [showSearch, setShowSearch] = useState(true);
  const [resourceData, setResourceData] = useState({});
  const bjAccount = useRef("");

  const showResult = useCallback(
    (resourceData, account) => {
      setResourceData(resourceData);
      setShowSearch(false);
      bjAccount.current = account;
    },
    [showSearch, resourceData]
  );

  const getBeansData = async (dateStr) => {
    const res = await getSmartBeansDetail({
      user_no: bjAccount.current,
      job_time: dateStr,
    });

    if (res.code == 0) {
      setResourceData(res.data);
    }
  };

  return (
    <div>
      {showSearch ? null : (
        <NavBar
          right={right}
          onBack={() => {
            setShowSearch(true);
          }}
        >
          查询智慧豆
        </NavBar>
      )}

      {showSearch ? (
        <SearchView showResult={showResult} />
      ) : (
        <SearchResult resourceData={resourceData} getBeansData={getBeansData} />
      )}
    </div>
  );
}

const right = (
  <div style={{ fontSize: 24 }}>
    <Space style={{ "--gap": "16px" }}>
      <MoreOutline />
    </Space>
  </div>
);
