import { Input, Button, Toast } from "antd-mobile";
import "./search.scss";
import { useCallback, useRef } from "react";
import { getSmartBeansDetail } from "../../../api/searchBeans";

export function SearchView(props) {
  const account = useRef("");
  const dateStr = useRef("");

  const onSearchBtnClicked = useCallback(async () => {
    const regex = /^BJ\d{7}$/;

    if (regex.test(account.current) === false) {
      Toast.show("请输入正确的必经学号！");
      return;
    }

    const res = await getSmartBeansDetail({
      user_no: account.current,
      job_time: dateStr.current,
    });
    if (res.code == 0 && res.data) {
      props?.showResult(res.data, account.current);
    }
  }, []);
  return (
    <div className="container">
      {/* <img
        style={{ position: "absolute", top: 80 }}
        width="60px"
        height="60px"
        src={require("../../../assets/images/common/logo.png")}
        alt="applicant"
      /> */}
      <img
        style={{}}
        width="100%"
        height="50%"
        src={require("../../../assets/images/search/search.jpg")}
        alt="applicant"
      />
      <div
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginTop: -320,
        }}
      >
        {/* <img
          width="100%"
          src={require("../../../assets/images/search/searchLine.jpg")}
          alt="applicant"
        /> */}
        <div style={{ position: "absolute", top: 100 }}>
          <Input
            style={{
              width: 260,
              height: 40,
              paddingLeft: 12,
              paddingRight: 12,
              border: "1px solid #e9e9e9",
              borderRadius: 20,
            }}
            onChange={(val) => {
              account.current = val;
            }}
            placeholder="请输入学号"
          />
          <Button
            onClick={onSearchBtnClicked}
            color="primary"
            style={{
              width: 285,
              height: 40,
              marginTop: 12,
              borderRadius: 20,
            }}
          >
            查询
          </Button>
        </div>
      </div>
    </div>
  );
}
