import {
  Tabs,
  Swiper,
  Ellipsis,
  PickerView,
  Dialog,
  Button,
} from "antd-mobile";
import { DownOutline } from "antd-mobile-icons";
import { SwiperRef } from "antd-mobile/es/components/swiper";
import { useRef, useState } from "react";
import { getSmartBeanDetailDateData } from "../../../utils/dateUtil";
import "./searchResult.scss";

const tabItems = [
  { key: "all", title: "全部明细" },
  { key: "add", title: "增加" },
  { key: "reduce", title: "消耗" },
];

const testData = [
  {
    reason: "挑战勇士4大原则",
    beans: 200,
    dateString: "2024-03-23",
  },
];
const [curYear, curMonth, yearData, monthData] = getSmartBeanDetailDateData();
const basicColumns = [yearData, monthData];

export function SearchResult(props) {
  const swiperRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [userInfo, setUserInfo] = useState({});

  const [allYear, setAllYear] = useState(curYear);
  const [allMonth, setAllMonth] = useState(curMonth);

  const updateAllSelectedDate = async (year, month) => {
    console.log(year, month);
    setAllYear(year);
    setAllMonth(month);
    const dateStr = month > 9 ? `${year}-${month}` : `${year}-0${month}`;
    props.getBeansData(dateStr);
  };

  const [reduceYear, setReduceYear] = useState(curYear);
  const [reduceMonth, setReduceMonth] = useState(curMonth);

  const updateReduceSelectedDate = (year, month) => {
    setReduceYear(year);
    setReduceMonth(month);
  };

  const [addYear, setAddYear] = useState(curYear);
  const [addMonth, setMonth] = useState(curMonth);
  const updateAddSelectedDate = (year, month) => {
    setAddYear(year);
    setMonth(month);
  };

  const renderDateSelected = (
    cb = () => {},
    defaultValue = [curYear, curMonth]
  ) => {
    let year, month;
    Dialog.alert({
      title: (
        <div
          style={{ color: "rgb(27, 103, 235)" }}
          className="adm-dialog-title"
        >
          月份选择
        </div>
      ),
      content: (
        <>
          <PickerView
            onChange={([selectedYear, selectedMonth]) => {
              year = selectedYear;
              month = selectedMonth;
            }}
            defaultValue={defaultValue}
            columns={basicColumns}
          />
        </>
      ),
      confirmText: (
        <Button
          onClick={() => {
            cb(year, month);
          }}
          style={{ width: "80%" }}
          size="large"
          shape="rounded"
          color="primary"
        >
          确定
        </Button>
      ),
    });
  };

  console.log(
    " props.resourceData?.bean_detail_list",
    props.resourceData?.bean_detail_list
  );

  const addList = props.resourceData?.bean_detail_list?.filter(
    (item) => item.type == 1
  );
  const reduceList = props.resourceData?.bean_detail_list?.filter(
    (item) => item.type == 2
  );

  let total = 0;
  for (let idx = 0; idx < props.resourceData?.bean_detail_list.length; idx++) {
    const item = props.resourceData?.bean_detail_list[idx];
    if (item.type == 1) {
      total += item.bean_amount;
    } else {
      total -= item.bean_amount;
    }
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "100%",
          paddingTop: 20,
          paddingRight: 12,
          paddingLeft: 12,
          backgroundColor: "rgb(27, 103, 235)",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", paddingLeft: 20 }}>
          <img
            style={{ borderRadius: 25 }}
            width="44px"
            height="44px"
            src={require("../../../assets/images/common/logo.png")}
            alt="applicant"
          />
          <div style={{ marginLeft: 12, fontSize: 16, color: "white" }}>
            {props.resourceData.user_no || ""}
          </div>
          <div style={{ marginLeft: 6, fontSize: 16, color: "white" }}>
            {props.resourceData.name || ""}
          </div>
        </div>
        <div style={{ marginTop: 28, paddingBottom: 80 }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ fontWeight: 600, fontSize: 30, color: "white" }}>
              {props.resourceData.bean_total || 0}
            </div>
            <div
              style={{
                marginTop: 8,
                marginLeft: 6,
                fontSize: 14,
                color: "white",
              }}
            >
              智慧豆
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          marginTop: -32,
          paddingTop: 24,
          borderTopLeftRadius: 12,
          borderTopRightRadius: 12,
          backgroundColor: "white",
        }}
      >
        <div
          onClick={() => renderDateSelected(updateAllSelectedDate)}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "space-between",
            width: "100%",
            fontSize: 18,
            fontWeight: 600,
            color: "black",
          }}
        >
          <div style={{ marginLeft: 20 }}>
            {`${allYear}年${allMonth}月`}
            <DownOutline style={{ marginLeft: 3, fontWeight: 600 }} />
          </div>
          <span
            style={{
              position: "absolute",
              right: 20,
              paddingLeft: 12,
              fontWeight: 400,
              fontSize: 14,
            }}
          >{`本月总计：${total}`}</span>
        </div>
        <Tabs
          activeKey={tabItems[activeIndex].key}
          onChange={(key) => {
            const index = tabItems.findIndex((item) => item.key === key);
            setActiveIndex(index);
            swiperRef.current?.swipeTo(index);
          }}
          style={{
            width: "80%",
            marginTop: 28,
          }}
        >
          {tabItems.map((item, idx) => (
            <Tabs.Tab
              style={{
                fontWeight: activeIndex == idx ? 600 : 400,
              }}
              ref={(node) => {
                if (node) {
                  node.style.setProperty("font-size", "16px", "important");
                }
              }}
              title={item.title}
              key={item.key}
            />
          ))}
        </Tabs>
        <Swiper
          direction="horizontal"
          loop
          indicator={() => null}
          ref={swiperRef}
          defaultIndex={activeIndex}
          onIndexChange={(index) => {
            setActiveIndex(index);
          }}
        >
          <Swiper.Item>
            {renderDetailItem(
              `${allYear}年${allMonth}月`,
              props.resourceData?.bean_detail_list || [],
              () => renderDateSelected(updateAllSelectedDate)
            )}
          </Swiper.Item>
          <Swiper.Item>
            {renderDetailItem(`${allYear}年${allMonth}月`, addList, () =>
              renderDateSelected(updateAllSelectedDate)
            )}
          </Swiper.Item>
          <Swiper.Item>
            {renderDetailItem(`${allYear}年${allMonth}月`, reduceList, () =>
              renderDateSelected(updateAllSelectedDate)
            )}
          </Swiper.Item>
        </Swiper>
      </div>
    </div>
  );
}

function renderDetailItem(dateStr = "", dataList = [], onDateClicked) {
  console.log("dataList:", dataList);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 15,
        paddingRight: 20,
        paddingLeft: 20,
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "flex-start",
          paddingBottom: 16,
          borderBottom: "1px solid #E6E6E6",
        }}
      ></div>
      {dataList?.length > 0 &&
        dataList.map((item) => {
          return (
            <div
              style={{
                width: "100%",
                paddingTop: 12,
                paddingBottom: 12,
                borderBottom: "1px solid #E6E6E6",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ fontSize: 16, fontWeight: 400, color: "black" }}>
                  {item.job_name}
                </div>
                <div
                  style={{
                    paddingRight: 6,
                    fontSize: 16,
                    fontWeight: 500,
                    color: "black",
                  }}
                >
                  {item.type == 1
                    ? `+${item.bean_amount}`
                    : `-${item.bean_amount}`}
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-start",
                  marginTop: 6,
                  color: "gray",
                }}
              >
                {dateStr}
              </div>
            </div>
          );
        })}
      {dataList?.length < 1 && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 66,
          }}
        >
          <img
            width="80px"
            height="60px"
            src={require("../../../assets/images/search/no_data.jpg")}
            alt="applicant"
          />
          <div style={{ marginTop: 6, fontSize: 14, color: "#8F8F8F" }}>
            当月无数据
          </div>
          <div style={{ marginTop: 8, fontSize: 14, color: "#8F8F8F" }}>
            可切换日期查看其它月份明细
          </div>
        </div>
      )}
    </div>
  );
}
