import request from "../utils/request";

export async function getSmartBeansDetail(params) {
  const res = await request({
    url: "/open/query_bean_detail",
    method: "get",
    params: params,
  });

  return res;
}
