const START_YEAR = 2017;

export function getCurrentYearAndMonth() {
  const date = new Date();
  const curYear = date.getFullYear();
  const curMonth = date.getMonth() + 1;

  return [curYear, curMonth];
}

export function getSmartBeanDetailDateData() {
  const [curYear, curMonth] = getCurrentYearAndMonth();

  const yearData = [];
  const monthData = [];

  for (let year = START_YEAR; year <= curYear; year++) {
    yearData.push({ label: `${year}年`, value: year });
  }

  for (let month = 1; month < 13; month++) {
    monthData.push({ label: `${month}月`, value: month });
  }

  return [curYear, curMonth, yearData, monthData];
}
