import * as React from "react";
import ReactDom from "react-dom";
import { SpinLoading } from "antd-mobile";

class Loading {
  constructor() {
    this.div = document.createElement("div");
    this.div.style.position = "absolute";
    this.div.style.top = "50%";
    this.div.style.left = "50%";
  }

  showLoading = () => {
    ReactDom.render(<SpinLoading color="default" />, this.div);
    document.body.appendChild(this.div);
  };

  hideLoading = () => {
    document.body.removeChild(this.div);
  };
}

const loading = new Loading();
export default loading;
